<template>
  <div class="base">
    <main class="main-body">
      <div class="security-body" id="register-body">
        <div class="text-center">
          <img :src="require('../assets/images/horizontal_logo.png')" width="200" height="40">
        </div>        

        <div class="text-center mt-3 mb-2 title">{{$t("CREATE ACCOUNT")}}</div>

        <form id="register" @submit.stop.prevent="register">
          <div class="form-group">
            <label for="email">{{$t("Username")}}</label>
            <b-form-input
              id="example-input-1"
              :placeholder="$t(`Enter username`)"
              name="example-input-1"
              v-model="$v.form.name.$model"
              :state="validateState('name')"
              aria-describedby="input-1-live-feedback"
              required
            ></b-form-input>
            <b-form-invalid-feedback id="input-1-live-feedback">{{$t("This is a required field.")}}</b-form-invalid-feedback>
          </div>

          <div class="form-group">
            <label for="email">{{$t("Email")}}</label>
            <b-form-input
              id="example-input-1"
              :placeholder="$t(`Enter email`)"
              name="example-input-1"
              v-model="$v.form.email.$model"
              :state="validateState('email')"
              aria-describedby="input-1-live-feedback"
              required
            ></b-form-input>
            <b-form-invalid-feedback id="input-1-live-feedback">{{$t("This is a required field.")}}</b-form-invalid-feedback>
          </div>

          <div class="form-group">
            <label for="password">{{$t("Password")}}</label>
            <div class="password-input">
              <input v-if="showPassword" type="text" class="form-control" :placeholder="$t(`Enter Password`)" v-model="$v.form.password.$model" required/>
              <input v-else type="password" class="form-control" :placeholder="$t(`Enter Password`)" v-model="$v.form.password.$model" required>
            <b-form-invalid-feedback id="input-1-live-feedback">{{$t("This is a required field.")}}</b-form-invalid-feedback>
              <b-button class="button" @click="toggleShow">
                <i :class="{ 'fas fa-eye-slash text-info-50': showPassword, 'fas fa-eye text-info-50': !showPassword }" aria-hidden="true"></i>
              </b-button>
            </div>
          </div>

          <div class="form-group">
            <label for="confirmPW">{{$t("Confirm Password")}}</label>
            <div class="password-input">
              <input v-if="showConfirmPassword" type="text" class="form-control" :placeholder="$t(`Confirm Password`)" v-model="$v.form.confirmPassword.$model" required/>
              <input v-else type="password" class="form-control" :placeholder="$t(`Confirm Password`)" v-model="$v.form.confirmPassword.$model" required>
              <b-button class="button" @click="toggleShowConfirmPassword">
                <i :class="{ 'fas fa-eye-slash text-info-50': showConfirmPassword, 'fas fa-eye text-info-50': !showConfirmPassword }" aria-hidden="true"></i>
              </b-button>
            </div>
          </div>

          <div class="row w-100" style="margin: 0px;">
            <div class="checkbox-group">
                <input type="checkbox" id="custom-checkbox" class="custom-checkbox" required>
                <span class="custom-checkbox-span" tabindex="0"></span>
            </div>
            <div class="small" style="width: 90%">
              <span>{{$t("I am over 18 years old and I have read and agree to the terms of the ")}}
                {{$t("Privacy Policy ")}}
                {{$t("and ")}}
                {{$t("Terms Of Use")}}</span>
            </div>
          </div>

          <div class="d-flex justify-content-center mt-5">
            <button type="submit" class="security-btn">{{$t("Sign Up")}}</button>
          </div>
        </form>

        <div class="mt-3 d-flex justify-content-center" id="bottom-container">
          <div>
            <span class="mr-2">{{$t("Already have an account?")}}</span>
            <router-link class="signin-btn" to="/login">
              {{$t("Sign In")}}
            </router-link>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import axios from "axios";
import { required, email } from "vuelidate/lib/validators";
import api from '../helper/api.js';
 

export default {
  data() {
    return {
      form: {
        email: '',
        password: '',
        name:'',
        confirmPassword:""
      },
      showPassword: false,
      showConfirmPassword: false,
      username: null,
      email: null,
      password: null,
      confirmPassword: null,
      // tradingPin: null
    };
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required,
        // minLength: minLength(8)
      },
      name:{
        required
      },
      confirmPassword:{
        required
      }
    }
  },
  computed: {
    buttonLabel() {
      return (this.showPassword) ? "Hide" : "Show";
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    toggleShowConfirmPassword() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
    
    async register(){
      // this.register = true;

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.isLogin = false;
        return;
      }

      try{
        // const api = await axios.post(`${baseUrl}/login`,{
        //   email: this.form.email,
        //   password: this.form.password
        // });

        // const response = api.data;

        // localStorage.setItem("token",response.access_token);

        // this.$router.push({'name':'Wallet'});
        let params = {
          name: this.form.name,
          email : this.form.email,
          password : this.form.password
        }
        let baseUrl = process.env.VUE_APP_CORE_BASE_URL;
        await axios.post(`${baseUrl}/operators/create`,params,{headers:{
            Authorization: 'Bearer ' + this.$route.query.token,
        }});

        // if(response.message.require_2fa != undefined){
        //   this.$router.push({name:'TwoFa',params: {email:this.form.email}});
        //   return;
        // }

        // localStorage.setItem("token",response.message.access_token);
        // await this.getUserType();
        this.$swal({
            text: this.$t("Register success"),
            icon: 'success',
            confirmButtonColor: '#33AD4A',
        }).then((result) => {
            if (result.isConfirmed) {
              this.$router.push({name:'Login'});
                // window.location.reload();
            }
        })


      }catch(error){
        this.$swal('Error',error.response.data.message,'error');
        // this.isLogin = false;
        console.log(error);
      }

    },
    async getUserType(){
        try{
            const response = await api.apiCall('core','GET','/operators');
            localStorage.setItem("userType",response.message.service_type);        
            localStorage.setItem("platform_id",response.message.platform_id);
            localStorage.setItem("google2fa",response.message.google2fa);
            localStorage.setItem("viewOperator",response.message.permission.SETTING )
            localStorage.setItem("userEmail",response.message.email);
            localStorage.setItem('swagger',response.message.swagger);
            localStorage.setItem("chargeUsd",response.message.charge_usd);
        }catch(error){
            console.error(error);
        }
    }

  }
};
</script>

<style>
@import '../css/styles.css';

#register-body {
  margin-top: 3%;
  width: 40%;
}

#bottom-container {
  color: #c6c8dd;
}

.signin-btn {
  color: var(--primary);
  cursor: pointer;
  outline: none;
  text-decoration: none;
}

.signin-btn:hover {
  cursor: pointer;
  outline: none;
  text-decoration: none;
  opacity: 0.5;
}

.password-input {
  display: flex;
  position: relative;
  align-items: center;
}

.password-input button {
  position: absolute;
  right: 1%;
  display: flex;
  border: none;
  background-color: transparent;
}

@media only screen and (max-width: 768px) {
  #register-body {
    margin:10% 5%;
    width: 100%;
  }
}
</style>